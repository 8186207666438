import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase';

const ManageVideos = () => {
  const [videos, setVideos] = useState([]);

  // Fetch all videos from Firestore
  useEffect(() => {
    const fetchVideos = async () => {
      const videoCollection = collection(db, 'videos');
      const videoSnapshot = await getDocs(videoCollection);
      const videoList = videoSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideos(videoList);
    };

    fetchVideos();
  }, []);

  // Handle video deletion
  const handleDelete = async (videoId, videoUrl) => {
    try {
      // Delete from Firebase Storage
      const storageRef = ref(storage, videoUrl);
      await deleteObject(storageRef);

      // Delete from Firestore
      await deleteDoc(doc(db, 'videos', videoId));

      // Update UI by filtering out the deleted video
      setVideos(videos.filter(video => video.id !== videoId));
      alert('Video deleted successfully!');
    } catch (error) {
      console.error('Error deleting video:', error);
      alert('Failed to delete video. Please try again.');
    }
  };

  return (
    <div>
      <h2>Manage Uploaded Videos</h2>
      <ul>
        {videos.map(video => (
          <li key={video.id}>
            <span>{video.name}</span>
            <button onClick={() => handleDelete(video.id, video.url)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageVideos;
