// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAAnTFzefOMD152Qem16I-s-HD1WjDhMA8",
    authDomain: "add-admin-1d522.firebaseapp.com",
    projectId: "add-admin-1d522",
    storageBucket: "add-admin-1d522.appspot.com",
    messagingSenderId: "610651286404",
    appId: "1:610651286404:web:00882c3c117b576f980bd1",
    measurementId: "G-GMS4QMC22F"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
