import React, { useEffect, useRef, useState } from 'react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/publicPlayer.css';

const PublicPlayer = () => {
  const [videoURL, setVideoURL] = useState('');
  const [nextVideoURL, setNextVideoURL] = useState('');
  const [isFadingOut, setIsFadingOut] = useState(false);
  const videoRef = useRef(null);
  const hiddenVideoRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'sync', 'currentVideo'), (doc) => {
      if (doc.exists()) {
        const videoData = doc.data();
        setNextVideoURL(videoData.url);
        if (videoRef.current) {
          videoRef.current.currentTime = videoData.currentTime || 0;
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (nextVideoURL && hiddenVideoRef.current) {
      const hiddenVideoElement = hiddenVideoRef.current;
      hiddenVideoElement.src = nextVideoURL;
      hiddenVideoElement.load(); // Start buffering the video
      
      hiddenVideoElement.addEventListener('canplaythrough', () => {
        // Only switch video when the next video is fully buffered
        if (videoRef.current) {
          setIsFadingOut(true);
          
          setTimeout(() => {
            setVideoURL(nextVideoURL);
            setIsFadingOut(false);
          }, 1000); // Duration should match the CSS transition time
        }
      });

      return () => {
        hiddenVideoElement.removeEventListener('canplaythrough', () => {});
      };
    }
  }, [nextVideoURL]);

  useEffect(() => {
    if (videoRef.current) {
      const updateCurrentTime = () => {
        const currentTime = videoRef.current.currentTime;
        updateDoc(doc(db, 'sync', 'currentVideo'), { currentTime });
      };

      const intervalId = setInterval(updateCurrentTime, 500); // Sync every 500ms

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  return (
    <div className="video-player-container">
      <video
        id="public-video-player"
        src={videoURL}
        muted
        loop
        playsInline
        ref={videoRef}
        preload="auto"
        className={`video-player ${isFadingOut ? 'fade-out' : 'fade-in'}`}
      />
      <video
        ref={hiddenVideoRef}
        className="hidden-video"
        preload="auto"
      />
    </div>
  );
};

export default PublicPlayer;
