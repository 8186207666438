// src/components/Admin.js
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import UploadVideos from './UploadVideos';
import ManageQueue from './ManageQueue';
import ManageVideos from './ManageVideos'; // Import the ManageVideos component
import './Admin.css'; // Import the CSS file for styling

const Admin = () => {
  return (
    <div className="admin-container">
      <nav className="sidebar">
        <ul>
          <li><Link to="/admin/upload">Upload Videos</Link></li>
          <li><Link to="/admin/manage">Manage Queue</Link></li>
          <li><Link to="/admin/manage-videos">Manage Videos</Link></li> {/* New Link for Manage Videos */}
        </ul>
      </nav>
      <div className="main-content">
        <Routes>
          <Route path="/upload" element={<UploadVideos />} />
          <Route path="/manage" element={<ManageQueue />} />
          <Route path="/manage-videos" element={<ManageVideos />} /> {/* New Route for Manage Videos */}
        </Routes>
      </div>
    </div>
  );
};

export default Admin;
