// src/components/ManageQueue.js
import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, setDoc } from "firebase/firestore";
import { db } from '../firebase';

const ManageQueue = () => {
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'videos'), (snapshot) => {
      const videoList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVideos(videoList);

      // Start playing the first video if none is currently playing
      if (!currentVideo && videoList.length > 0) {
        playVideoByIndex(0, videoList);
      }
    });

    return () => unsubscribe();
  }, [currentVideo]);

  const playVideoByIndex = async (index, videoList = videos) => {
    if (index >= videoList.length) return; // If the index exceeds the list, stop

    const video = videoList[index];
    setCurrentIndex(index);
    setCurrentVideo(video);

    const docRef = doc(db, 'sync', 'currentVideo');
    await setDoc(docRef, {
      videoId: video.id,
      url: video.url,
      timestamp: Date.now()
    });

    console.log(`Playing video: ${video.name}`);
  };

  const handleVideoEnd = () => {
    let nextIndex = currentIndex + 1;
    if (nextIndex >= videos.length) {
      nextIndex = 0; // Loop back to the first video
    }
    playVideoByIndex(nextIndex);
  };

  return (
    <div>
      <h2>Manage Video Queue</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {videos.map((video, index) => (
          <li
            key={video.id}
            style={{
              padding: '10px',
              margin: '0 0 10px 0',
              backgroundColor: '#f4f4f4',
              borderRadius: '4px',
              border: index === currentIndex ? '2px solid #007bff' : '1px solid #ddd'
            }}
          >
            {video.name}
            {index === currentIndex && (
              <button onClick={handleVideoEnd} style={{ float: 'right' }}>End Current Video</button>
            )}
          </li>
        ))}
      </ul>
      {currentVideo && (
        <div>
          <p>Currently Playing: {currentVideo.name}</p>
          <video
            src={currentVideo.url}
            autoPlay
            controls
            onEnded={handleVideoEnd}
            style={{
              width: '320px', // Fixed width
              height: '180px', // Fixed height
              border: '1px solid #ddd',
              borderRadius: '4px'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ManageQueue;
